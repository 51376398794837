<template>
  <el-main>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <el-table-column prop="type" label="企业/个人" align="center">
        <template v-slot="{ row }">
          {{ row.enterprise_type == 2 && row.type == 1 ? '个体' : row.type == 1 ? '企业' : '个人' }}
        </template>
      </el-table-column>
      <el-table-column prop="enterprise_type" label="企业类别" align="center">
        <template v-slot="{ row }">
          {{ row.enterprise_type == 1 ? '工厂店' : row.enterprise_type == 2 ? '广告店' : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="service_type" label="服务类别" align="center">
        <template v-slot="{ row }">
          {{ row.service_type == 1 ? '广告类' : row.service_type == 2 ? '安装类' : '设计类' }}
        </template>
      </el-table-column>
      <el-table-column prop="fixed_rights" label="所选权益" align="center">
        <template v-slot="{ row }">
          <span v-if="Array.isArray(row.fixed_rights)">--</span>
          <div v-else>
            {{ row.fixed_rights.is_website == 1 ? '网站素材、' : '' }}
            {{ row.fixed_rights.is_forum == 1 ? '论坛发布、' : '' }}
            {{ row.fixed_rights.is_commission == 1 ? '推广佣金、' : '' }}
            {{ row.fixed_rights.is_advertising == 1 ? '广告位、' : '' }}
            {{ row.fixed_rights.is_assign == 1 ? '指定派单、' : '' }}
            {{ row.fixed_rights.is_customization_store == 1 ? '定制商城、' : '' }}
          </div>
          <span v-for="(item, index) in row.custom_rights" :key="index">{{ item.name }}、</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="入驻费用" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="selectRight(scope.row)" type="text" size="small">选择权益</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="选择权益" :visible.sync="showSet" width="620px">
      <el-form label-width="100px">
        <el-form-item label="企业/个人：">{{ currentInfo.type == 1 ? '企业' : '个人' }}</el-form-item>
        <el-form-item label="企业类别：">{{ currentInfo.enterprise_type == 1 ? '工厂店' : currentInfo.enterprise_type == 2 ? '广告店' : '--' }}</el-form-item>
        <el-form-item label="服务类别：">{{ currentInfo.service_type == 1 ? '广告类' : currentInfo.service_type == 2 ? '安装类' : '设计类' }}</el-form-item>
        <el-form-item label="入驻费用：">
          <el-input v-model="currentInfo.amount"></el-input>
        </el-form-item>
        <el-form-item label="选择权益：">
          <el-checkbox :indeterminate="isIndeterminate" v-model="fixedCheckAll" @change="handleCheckAllChange($event, 1)">全选&nbsp;&nbsp;&nbsp;固定权益</el-checkbox>
          <el-checkbox-group v-model="fixedCheckedRights" @change="handleCheckedChange($event, 1)">
            <el-checkbox v-for="right in fixedRights" :label="right" :key="right">{{ right.split(',')[0] }}</el-checkbox>
          </el-checkbox-group>
          <el-checkbox :indeterminate="isIndeterminate1" v-model="customCheckAll" @change="handleCheckAllChange($event, 0)">全选&nbsp;&nbsp;&nbsp;自定义权益</el-checkbox>
          <el-checkbox-group v-model="customCheckedRights" @change="handleCheckedChange($event, 0)">
            <el-checkbox v-for="right in customRights" :label="right" :key="right">{{ right.split(',')[0] }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="权益说明：">
          <ReadyUploadSource @getSource="getImg" @changeOrder="changeOrder" @removeThis="removeImageThis" :isMany="true" :manyPath="picture"></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSet = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmSet">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import ReadyUploadSource from '@/components/readyUploadSource';
const fixedRightOptions = ['素材网站,is_website', '论坛发布,is_forum', '推广佣金,is_commission', '广告位,is_advertising', '指派订单,is_assign', '定制商城,is_customization_store'];
let customRightOptions = [];
export default {
  components: {
    ReadyUploadSource,
  },
  data() {
    return {
      showSet: !1,
      list: [],
      currentInfo: {},
      fixedCheckAll: !1,
      fixedRights: fixedRightOptions,
      fixedCheckedRights: [],
      isIndeterminate: !1,
      customCheckAll: !1,
      customRights: [],
      picture: [],
      customCheckedRights: [],
      isIndeterminate1: !1,
    };
  },
  created() {
    this.getList();
    this.getMarketing();
  },
  mounted() {},
  methods: {
    getImg(val) {
      let arr = [...this.picture];
      for (let i in val) arr.push(val[i].path);
      this.picture = arr;
    },
    changeOrder(val) {
      this.picture = val;
    },
    removeImageThis(index) {
      this.picture.splice(index, 1);
    },
    handleCheckAllChange(val, type) {
      if (type) {
        this.fixedCheckedRights = val ? fixedRightOptions : [];
        this.isIndeterminate = !1;
      } else {
        this.customCheckedRights = val ? customRightOptions : [];
        this.isIndeterminate1 = !1;
      }
    },
    handleCheckedChange(value, type) {
      if (type) {
        let checkedCount = value.length;
        this.fixedCheckAll = checkedCount === this.fixedRights.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.fixedRights.length;
      } else {
        let checkedCount = value.length;
        this.customCheckAll = checkedCount === this.customRights.length;
        this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.customRights.length;
      }
    },
    getList() {
      this.$axios.post(this.$api.samecity.rightSet).then(res => {
        if (res.code == 0) {
          this.list = res.result;
        }
      });
    },
    selectRight(row) {
      let fixedRights = _.cloneDeep(fixedRightOptions);
      let customRights = _.cloneDeep(customRightOptions);
      let fixedCheckedRights = [];
      let fixedCheckedRightsKey = [];
      let customCheckedRightsKey = [];
      this.showSet = !0;
      //处理固定权益
      if (!Array.isArray(row.fixed_rights)) {
        for (let i in fixedRights) {
          fixedRights[i] = fixedRights[i].split(',');
        }
        for (let key in row.fixed_rights) {
          if (row.fixed_rights[key] == 1) {
            fixedCheckedRights.push(key);
          }
        }
        for (let i in fixedCheckedRights) {
          for (let y in fixedRights) {
            if (fixedRights[y][1] == fixedCheckedRights[i]) {
              fixedCheckedRightsKey.push(fixedRights[y].join(','));
            }
          }
        }
      } else {
        row.fixed_rights = {
          is_website: 0,
          is_forum: 0,
          is_commission: 0,
          is_advertising: 0,
          is_assign: 0,
          is_customization_store: 0,
        };
      }
      //处理自定义权益
      if (row.custom_rights.length) {
        for (let i in customRights) {
          customRights[i] = customRights[i].split(',');
        }
        for (let i in row.custom_rights) {
          for (let y in customRights) {
            if (customRights[y][1] == row.custom_rights[i].id) {
              customCheckedRightsKey.push(customRights[y].join(','));
            }
          }
        }
      }
      this.fixedCheckedRights = fixedCheckedRightsKey;
      this.customCheckedRights = customCheckedRightsKey;
      this.handleCheckedChange(fixedCheckedRightsKey, 1);
      this.handleCheckedChange(customCheckedRightsKey, 0);
      row.amount = row.amount || '';
      this.picture = row.picture;
      this.currentInfo = _.cloneDeep(row);
    },
    getMarketing() {
      this.$axios
        .post(this.$api.samecity.marketingList, {
          rows: 100000,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            let customRights = [];
            list.map(item => customRights.push(item.name + ',' + item.id));
            customRightOptions = customRights;
            this.customRights = customRights;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    comfirmSet() {
      let data = _.cloneDeep(this.currentInfo);
      let fixedCheckedRights = _.cloneDeep(this.fixedCheckedRights);
      let customCheckedRights = _.cloneDeep(this.customCheckedRights);
      let noSelectFixedRight = _.cloneDeep(fixedRightOptions);
      let custom_rights_id = [];
      for (let i in noSelectFixedRight) {
        for (let y in fixedCheckedRights) {
          if (noSelectFixedRight[i] == fixedCheckedRights[y]) {
            noSelectFixedRight.splice(i, 1);
          }
        }
      }
      for (let i in fixedCheckedRights) {
        fixedCheckedRights[i] = fixedCheckedRights[i].split(',');
        for (let key in data.fixed_rights) {
          if (fixedCheckedRights[i][1] == key) {
            data.fixed_rights[key] = 1;
          }
        }
      }
      for (let i in customCheckedRights) {
        customCheckedRights[i] = customCheckedRights[i].split(',');
        custom_rights_id.push(customCheckedRights[i][1]);
      }
      noSelectFixedRight.map(item => {
        item = item.split(',');
        data.fixed_rights[item[1]] = 0;
      });
      data.custom_rights = custom_rights_id.join(',');
      data.amount = data.amount || 0;
      data.picture = this.picture;
      this.$axios.post(this.$api.samecity.editRight, data).then(res => {
        if (res.code == 0) {
          this.showSet = !1;
          this.getList();
          this.$message.success('设置成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>